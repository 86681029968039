import tezay from './img/tezay.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img className="App__img" src={tezay} alt="Tezay"></img>
    </div>
  );
}

export default App;
